/* @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
* {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Raleway', sans-serif;
}

.ant-card-body {
  padding: 0;
}
.ant-modal-body {
  padding: 0;
}

.footer {
  background-color: transparent;
}

.reset-padding {
  padding: 24px;
}

.vertical-hr {
  border: 0;
  margin: 0;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 100%;
  width: 1px;
}

@media (max-width: 767.98px) {
  .navbar-nav .nav-link {
    color: #000 !important;
  }
}

.carousel-caption {
  display: inherit !important;
}

.carousel-caption p {
  display: none;
}

.carousel-caption h3 {
  color: #d30a2a;
}

.carousel-item {
  max-height: 500px;
}

/* .order-status-tag {
  position: absolute;
  top: -2px;
  right: -9px;
} */
.ant-table-row-hover td {
  background-color: #D5DBEA !important;
  /* color: #FFF; */
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #D5DBEA;
}

